import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { filter, Subscription } from 'rxjs';
import { API_END_POINTS } from '../../constants/constant';
import { HeaderFilterModalComponent } from '../../features/modals/header-filter-modal/header-filter-modal.component';
import { LineData } from '../../interfaces/line.interface';
import { FactoryData, PlantData } from '../../interfaces/plant.interface';
import { CommonService } from '../../services/common.service';
import { HeaderService } from '../../services/header.service';
import { HttpService } from '../../services/http.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { SortByComponent } from '../sort-by/sort-by.component';
import { ToggleComponent } from '../toggle/toggle.component';
import { LocalStorageServiceService } from '@services/local-storage-service.service';
import { get } from 'lodash';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    SortByComponent,
    CommonModule,
    ToggleComponent,
    MultiSelectModule,
    FormsModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isDailyProduction: any;
  lineId: any;
  plantId: any;
  factoryId: any;
  selectedLines: any;
  selectedFactory: FactoryData[] = [];
  lineData!: LineData[];
  ref!: DynamicDialogRef;
  plantData!: PlantData[];
  factoryData!: FactoryData[];
  isMobile: boolean = false;
  isDisabled: boolean = false;
  isMobileView: boolean = false;
  isAllDisabled: boolean = false;
  isDailyLineRoute: boolean = false;
  isBackBtnRequired: boolean = false;
  autoRefreshEnabled: boolean = false;
  lastApiCallTime: string | null = null;
  chartHeading: string = 'Monthly Production';
  private subscriptions: Subscription = new Subscription();
  headerNavigation: any;

  constructor(
    public sidebarService: SidebarService,
    private httpService: HttpService,
    private commonService: CommonService,
    private headerService: HeaderService,
    private datePipe: DatePipe,
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private router: Router,
    private localStorageService: LocalStorageServiceService
  ) {}

  ngOnInit(): void {
    this.observeBreakpoints();
    this.subscribeToChartHeading();
    this.initializeLocalStorageValues();
    this.subscribeToHeaderService();
    this.checkActiveRoute(this.router.url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.checkActiveRoute(event.urlAfterRedirects);
      });
  
    this.getPlantData();
    if (this.plantId) this.getFactoryData();
    if (this.factoryId) {
      this.getLineData();
    } else {
      if (this.plantId) this.getLineDataByPlant();
    }
  }
  
  private observeBreakpoints(): void {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches;
        this.isMobileView = state.matches;
        this.commonService.isMobileSubject.next(state.matches);
      });
  }
  
  private subscribeToChartHeading(): void {
    this.commonService.currentChartHeading.subscribe((heading) => {
      this.chartHeading = heading;
      this.isBackBtnRequired = false;
      this.headerNavigation = this.localStorageService.getItem('header-navigation') || null;
  
      if (this.headerNavigation) {
        this.localStorageService.removeItem('header-navigation');
      }
  
      this.updateHeaderState();
      this.headerService.fetchSource.next();
  
      if (this.headerNavigation?.isBackBtnRequired || this.chartHeading === 'Quality Analysis') {
        this.isBackBtnRequired = true;
      }
    });
  }

  private updateLineFactoryAndPlant() {
    this.plantId = localStorage.getItem('plantId');
    if (this.plantId) this.headerService.setPlantId(this.plantId);
  
    this.factoryId = localStorage.getItem('factoryId');
    if (this.factoryId) this.headerService.setFactoryId(this.factoryId);
  
    this.lineId = localStorage.getItem('lineId');
    if (this.lineId) this.headerService.setLineId(this.lineId);
  }
  
  private initializeLocalStorageValues(): void {
    this.updateLineFactoryAndPlant();
    const storedLines = localStorage.getItem('selectedLines');
    if (storedLines && storedLines !== 'undefined') {
      this.selectedLines = JSON.parse(storedLines);
      this.headerService.setLineIds(this.selectedLines);
    }
  
    this.autoRefreshEnabled = this.headerService.getInitialAutoRefreshState();
    if (this.autoRefreshEnabled) this.headerService.setAutoRefresh(true);
    
    this.headerService.lastApiCallTime.subscribe((time) => {
      this.lastApiCallTime = time ? this.formatDateTime(time) : null;
    });
  }
  
  private updateHeaderState(): void {
    const disabledStates = [
      'User Management',
      'Line Management',
      'Production Loss Reason',
      'Alerts Management',
    ];
  
    if (disabledStates.includes(this.chartHeading)) {
      this.isDisabled = true;
      this.isAllDisabled = false;
      this.headerService.removeLineId();
      this.updateLineFactoryAndPlant();
    } else if (this.chartHeading === 'Manage Plants') {
      this.plantId = null;
      this.factoryId = null;
      this.lineId = null;
      this.isAllDisabled = true;
      this.isDisabled = false;
    } else {
      this.isAllDisabled = false;
      this.isDisabled = false;
      this.updateLineFactoryAndPlant();
    }
  }
  
  private subscribeToHeaderService(): void {
    this.subscriptions.add(
      this.headerService.lineId.subscribe((lineId: any) => {
        this.lineId = lineId;
        const line = this.lineData?.find((line) => line?.id === lineId);
        const factoryId = line ? line?.factoryId : undefined;
        if (factoryId) this.selectFactoryByLine(factoryId);
      })
    );
  
    this.subscriptions.add(
      this.headerService.factoryId.subscribe((factoryId: string | null) => {
        this.factoryId = factoryId;
        if (this.factoryId) this.getLineData();
        if (this.plantId) this.getFactoryData();
      })
    );
  
    this.subscriptions.add(
      this.headerService.lineIds.subscribe((lineIds: string[] | null) => {
        if (lineIds) {
          this.selectedLines = lineIds;
          localStorage.setItem(
            'selectedLines',
            JSON.stringify(this.selectedLines)
          );
        } else {
          this.selectedLines = [];
        }
      })
    );
  }


  private checkActiveRoute(url: string): void {
    if (url === '/chart/hourly-production') {
      this.isDailyLineRoute = true;
    } else {
      this.isDailyLineRoute = false;
    }
    if (url === '/chart/daily-production') {
      this.isDailyProduction = true;
    } else {
      this.isDailyProduction = false;
    }
  }

  getPlantData() {
    const url = API_END_POINTS.GET_PLANT;
    let params = new HttpParams().set('PageNumber', 0).set('PageSize', 0);
    this.httpService
      .getRequest(url, null, { params })
      .then((response) => {
        if (response) {
          this.plantData = response.data.filter((plant: any) => plant.isActive);
          localStorage.setItem('plantData', JSON.stringify(this.plantData));
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  getFactoryData() {
    const url = `${API_END_POINTS.GET_FACTORY_BY_PLANTID}${this.plantId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.factoryData = response.data;
          if (this.factoryId && this.isDailyProduction) {
            this.factoryId = String(this.factoryId);
            this.selectedFactory = [];
            this.factoryId
              .split(',')
              .map(Number)
              .forEach((element: number) => {
                const index = this.factoryData.findIndex((item) => {
                  return item.id === element;
                });
                if (index !== -1) {
                  this.selectedFactory.push(this.factoryData[index]);
                }
              });
          }
          localStorage.setItem('factoryData', JSON.stringify(this.factoryData));
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  getLineData() {
    const url = `${API_END_POINTS.GET_LINE_BY_FACTORYID}${this.factoryId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.lineData = response;
          localStorage.setItem('lineData', JSON.stringify(this.lineData));
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }
  getLineDataForMultipleFactors() {
    let factoryIds = '';
    if (this.selectedFactory) {
      this.selectedFactory.forEach((element) => {
        factoryIds = factoryIds + `${element.id},`;
      });
      factoryIds = factoryIds.slice(0, -1);
    }
    const url = `${API_END_POINTS.GET_LINE_BY_FACTORYID}${factoryIds}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.lineData = response;
          localStorage.setItem('lineData', JSON.stringify(this.lineData));
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  onToggleChange(isChecked: boolean): void {
    this.autoRefreshEnabled = isChecked;
    this.headerService.setAutoRefresh(isChecked);
  }

  onPlantChange(selectedPlant: PlantData) {
    this.plantId = selectedPlant.id;
    localStorage.setItem('plantId', this.plantId.toString());
    this.headerService.setPlantId(this.plantId);
    this.factoryId = null;
    this.lineId = null;
    this.selectedLines = [];
    this.headerService.removeFactoryId();
    this.headerService.removeLineId();
    this.headerService.removeLineIds();
    this.headerService.fetchSource.next();
    this.getFactoryData();
    this.getLineDataByPlant();
  }

  onFactoryChange(selectedFactory: FactoryData) {
    this.factoryId = selectedFactory.id;
    localStorage.setItem('factoryId', this.factoryId);
    this.headerService.setFactoryId(this.factoryId);
    this.headerService.removeLineId();
    this.headerService.removeLineIds();
    this.headerService.fetchSource.next();
    if (this.factoryId) this.getLineData();
  }
  onFactorySelectionChange(event: any) {
    localStorage.setItem(
      'selectedFactory',
      JSON.stringify(this.selectedFactory)
    );
    this.headerService.setFactoryIds(this.selectedFactory);
    this.headerService.removeLineId();
    this.headerService.removeLineIds();
    this.headerService.fetchSource.next();
    if (this.selectedFactory.length) {
      this.getLineDataForMultipleFactors();
    }
    else {
      this.factoryId = null;
      this.lineId = null;
      this.selectedLines = [];
      this.headerService.removeFactoryId();
      this.headerService.removeLineId();
      this.headerService.removeLineIds();
      this.getFactoryData();
      this.getLineDataByPlant();

    }
  }

  onLineChange(selectedLine: LineData) {
    this.lineId = selectedLine.id;
    localStorage.setItem('lineId', this.lineId);
    this.headerService.setLineId(this.lineId);
    this.headerService.fetchSource.next();
    this.selectFactoryByLine(selectedLine.factoryId);
  }
  onLineSelectionChange(event: any) {
    localStorage.setItem('selectedLines', JSON.stringify(this.selectedLines));
    this.headerService.setLineIds(this.selectedLines);
    this.headerService.fetchSource.next();
  }

  selectFactoryByLine(factoryId: any) {
    const selectedFactory = this.factoryData?.find(
      (factory) => factory.id === factoryId
    );
    if (selectedFactory) {
      this.factoryId = selectedFactory.id;
      localStorage.setItem('factoryId', this.factoryId);
      this.headerService.setFactoryId(this.factoryId);
      if (this.factoryId) this.getLineData();
    }
  }

  onManualRefresh(): void {
    this.headerService.setManualRefresh();
  }

  formatDateTime(dateTime: string): string {
    return this.datePipe.transform(dateTime, 'yyyy, MMMM d, HH:mm') || '';
  }

  getLineDataByPlant() {
    const url = `${API_END_POINTS.GET_ALL_PLANT_LINES}${this.plantId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.lineData = response;
          localStorage.setItem('lineData', JSON.stringify(this.lineData));
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  toggleSidebar() {
    this.sidebarService.toggle();
  }

  onOpenFilterModal() {
    this.ref = this.dialogService.open(HeaderFilterModalComponent, {
      data: { action: 'add' },
      width: '480px',
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });
    this.ref.onClose.subscribe((result) => {
      if (result === undefined) return;
      if (result) {
        const form = get(result, 'form.value');
        const selectedLines = get(result, 'selectedLines');
        if (form?.plant) {
          this.plantId = form?.plant?.id;
          localStorage.setItem('plantId', this.plantId.toString());
          this.headerService.setPlantId(this.plantId);
          if (form?.factory) {
            this.factoryId = form?.factory?.id;
            localStorage.setItem('factoryId', this.factoryId);
            this.headerService.setFactoryId(this.factoryId);
            if (form?.line) {
              this.lineId = form?.line?.id;
              localStorage.setItem('lineId', this.lineId);
              this.headerService.setLineId(this.lineId);
            } else if (selectedLines?.length) {
              this.selectedLines = selectedLines;
              localStorage.setItem(
                'selectedLines',
                JSON.stringify(this.selectedLines)
              );
              this.headerService.setLineIds(this.selectedLines);
            } else {
              this.headerService.removeLineId();
              this.headerService.removeLineIds();
            }
          } else {
            localStorage.removeItem('lineId');
            localStorage.removeItem('plantId');
            this.headerService.removeFactoryId();
            this.headerService.removeLineId();
            this.headerService.removeLineIds();
          }
          this.headerService.fetchSource.next();
        } else {
          this.clearAll();
        }
      } else {
        this.clearAll();
      }
    });
  }

  clearAll() {
    localStorage.removeItem('factoryId');
    localStorage.removeItem('lineId');
    localStorage.removeItem('plantId');
    this.headerService.removeFactoryId();
    this.headerService.removeLineId();
    this.headerService.removeLineIds();
    this.headerService.fetchSource.next();
    this.router.navigate(['/chart/daily-production']);
  }

  onBackClick() {
    if (this.headerNavigation) {
      this.localStorageService.removeItem('header-navigation');
      this.router.navigate([this.headerNavigation?.route]);
    } else this.router.navigate(['chart/module-equipment']);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.headerNavigation) {
      this.localStorageService.setItem(
        'header-navigation',
        this.headerNavigation
      );
    }
  }
}
